@import "../../common/common.scss";

.personal-info {
    align-self: flex-start;
    justify-self: flex-end;
}

.page {
    .back {
        font-size: 20px;

        // position: absolute;
        // left: 0;
        // transform: translateX(-100%) translateX(-20px);
        // height: 100%;
        // display: flex;
        // align-items: center;

        &:hover {
            text-decoration: underline;
        }
    }

    .external-link {
        display: inline-flex;
        margin-bottom: 20px;
        text-decoration: underline;
        font-size: 20px;
        width: 100%;
        align-items: center;
        justify-content: center;

        .logo {
            width: 25px;
            height: 25px;
            margin-right: 10px;
        }

        &:hover {
            color: darken($trimary, 20%);
        }
    }

    .content {
        margin: auto;
        max-width: 800px;
    }

    .title {
        margin-top: 0;
        position: relative;
        // margin: 25px 0;
        h1 {
            margin-bottom: 25px;
            margin-top: 10px;
        }

        font-size: 28px;
    }

    .body {
        .part {
            margin-top: 20px;
            margin-bottom: 20px;

            &.text {
                font-size: 20px;
                line-height: 1.4em;
            }

            &.image {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;

                font-style: italic;
                font-size: 14px;

                img {
                    max-height: 500px;
                }
            }

            &.side {
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;

                & > * {
                    overflow: hidden;
                }
            }
        }
    }

    .empty {
        text-align: center;
        margin-top: 30px;
        font-style: italic;
        font-size: 20px;
        font-weight: 200;
    }
}
