@import "../../common/common.scss";

.container {
    font-size: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1.4em;

    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .link {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: underline;
    }

    .logo {
        $full-s: 96px;
        $logo-s: 64px;

        width: $logo-s;
        height: $logo-s;
        padding: ($full-s - $logo-s) / 2;
        border-radius: 5px;

        background-size: $logo-s;
        background-position: center;
        background-repeat: no-repeat;

        transition: background-color $anim-time;
        &:hover {
            background-color: darken($background, 15%);
        }
    }
}
