@import "../../common/common.scss";

.container {
    margin: auto;
}

.title {
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }

    text-align: center;
    max-width: 600px;
    margin: auto;

    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 80px;
}
