@import "../../common/common.scss";

.container {
    z-index: 10000;

    display: flex;
    flex-direction: column;

    position: absolute;
    top: 20px;
    left: 20px;

    @include neumorphic;
    padding: 20px;

    h2 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .headerButton {
        @include normalise-button;
        cursor: pointer;
        text-align: left;
        display: flex;

        .indicator {
            margin-left: 10px;
            transition: transform $anim-time;
        }
    }

    .links {
        display: flex;
        flex-direction: column;

        text-decoration: underline;
        font-size: 20px;;

        & > * {
            margin-top: 15px;
        }

        height: 0;
        overflow: hidden;
        transition: height $anim-time;
        .expanded {
            height: unset;
        }

        a.current {
            font-weight: 700;
            font-style: italic;
        }
    }

    &.expanded {
        .links {
            height: unset;
        }

        .indicator {
            $x: 2px;
            $y: 3px;
            transform: translate($x, $y) rotate(90deg) translate(-$x, -$y);
        }
    }
}
