@import "../../common/common.scss";

.aboutMe {
    font-size: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 30px;
    line-height: 1.4em;

    // @include neumorphic;
    // padding: 20px;
}

.projectsLink {
    margin: 0;
    text-align: center;
    text-decoration: underline;
}
