@import "../../common/common.scss";

.container {
    margin: auto;
    max-width: 800px;
}

.title {
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 80px;
}

.content {
    font-size: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1.4em;

    a {
        text-decoration: underline;
    }

    .subject {
        margin-top: 40px;

        .subjectContent {
            display: flex;
        }

        .tile {
            $s: 350px;

            width: $s;
            min-width: $s;
            max-width: $s;
        }

        .info {
            margin-left: 30px;
            margin-top: 10px;

            strong {

            }
        }
    }
}
