@import "../../common/common.scss";

.container {
    margin: auto;
    max-width: 800px;

    h4 {
        margin-bottom: 10px;
    }
}

.title {
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 80px;
}

.content {
    font-size: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    line-height: 1.4em;


    .listContainer {
        display: flex;
        justify-content: space-around;

        li {
            margin-top: 10px;
        }
    }
}
