.github-logo {
    background-image: url('../img/logos/small-GitHub-Mark.svg');
}

.linkedin-logo {
    background-image: url('../img/logos/LI-In-Bug.png');
}

.email-logo {
    background-image: url('../img/email-24px.svg');
}

.document-logo {
    background-image: url('../img/description-24dp.svg');
}
