@import "../../common/common.scss";

.tile-list {
    // display: grid;
    // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    gap: 30px;
}

.tile-item-wrapper {
    max-width: 400px;
    min-width: 350px;
    flex-grow: 1;
    display: block;

    transition: transform $anim-time;
    &:focus {
        transform: scale(1.1);
    }
}

.tile-item {
    $p: 1/1.25;
    padding-bottom: 100% * $p;
    position: relative;

    .tile-item-sizer {
        @include neumorphic;

        position: absolute;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
    }

    .thumbnail {
        flex: 1;
        // min-height: 80%;
        background: darken($background, 1%);
        border-radius: $radius $radius 0 0;

        div {
            width: 100%;
            height: 100%;

            border-radius: $radius $radius 0 0;

            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;

            &.contain {
                background-size: contain;
            }
        }
    }

    .details {
        padding: 5px;
        overflow: hidden;
        // white-space: nowrap;
        text-overflow: ellipsis;
        text-align: right;

        display: flex;
        justify-content: space-between;
        align-items: center;

        .tag-list {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            font-size: 16px;

            li {
                @include neumorphic-small;
                padding: 5px;
                border-radius: 10px;

                &.active {
                    background: $active-color;
                }
            }
        }
    }

    h3 {
        margin: 0;
        padding-right: 5px;
        font-size: 18px;
    }
}
