@import "../../common/common.scss";

.container {
    margin: auto;
    max-width: 800px;
}

.title {
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 80px;
}

.content {
    font-size: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 30px;
    line-height: 1.4em;

    a {
        text-decoration: underline;
    }

    .img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        font-size: 14px;
    }

    img {
        max-width: 100%;
        max-height: 400px;
        margin-bottom: 10px;
    }

    li {
        margin-top: 10px;
    }

    h2 {
        margin-top: 50px;
    }
}
