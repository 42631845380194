@import "../../common/common.scss";

.profile {
    display: flex;
    gap: 20px;
    align-items: center;

    position: absolute;
    top: 20px;
    right: 20px;

    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }

    .photo {
        @include neumorphic;

        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        background-image: url('../../img/tim.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 50%;
    }

    .links {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        $full-s: 32px;
        $logo-s: 24px;

        .logo {
            width: $logo-s;
            height: $logo-s;
            padding: ($full-s - $logo-s) / 2;
            border-radius: 5px;

            background-size: $logo-s;
            background-position: center;
            background-repeat: no-repeat;

            transition: background-color $anim-time;
            &:hover {
                background-color: darken($background, 15%);
            }
        }
    }
}
