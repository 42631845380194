@import "../../common/common.scss";

.home {
    font-size: 20px;
    margin: auto;
    max-width: 800px;

    margin-top: 60px;

    a {
        h3 {
            text-decoration: underline;
        }
    }
}

.title {
    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 80px;
}
