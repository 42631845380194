@import "../../../common/common.scss";

.tag-adder {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: min-content;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    margin-top: 10px;
    padding: 20px;
    @include neumorphic;

    h4 {
        margin: 0;
        align-self: flex-start;
    }

    .input {
        position: relative;
        width: 100%;
        max-width: 400px;

        input[type=text] {
            @include neumorphic-small-inset;
            border-radius: $radius;
            padding: 10px 15px;
            // border-radius: 5px;
            border: none;
            background: white;
            box-sizing: border-box;
            width: 100%;
        }

        input[type=submit] {
            cursor: pointer;
            // inset button on end of input
            box-shadow: -2.5px -2.5px 5px #fafbff inset, 0px 7.5px 5px -5px #a6abbd inset;
            border-radius:  0 20px 20px 0;

            background-image: url('../../../img/add_icon.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 75%;

            height: 36px;
            width: 36px;
            border: none;
            border-left: 1px solid $background;

            position: absolute;
            right: 0;

            transition: background-color $anim-time;
            &:hover {
                background-color: darken($background, 10%);
            }
        }
    }

    ul {
        align-self: flex-start;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        list-style: none;

        margin: 16px;
        padding: 0;

        @keyframes fade-in {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        li {
            @include neumorphic;
            white-space: nowrap;

            overflow: hidden;
            animation: fade-in $anim-time;

            transition: background-color $anim-time, transform $anim-time;
            &:hover {
                background-color: darken($background, 10%);
            }

            &.active {
                background-color: $active-color;

                &:hover {
                    background-color: darken($active-color, 10%);
                }
            }

            &:focus-within {
                transform: scale(1.1);
            }

            $padding: 10px;
            button {
                @include normalise-button;
                background: none;
                padding: $padding;
                border-radius: $radius;
                cursor: pointer;
                position: relative;

                &:after {
                    content: ' ';
                    width: 0;
                    height: 1px;
                    display: block;
                    background: black;
                    transition: width $anim-time;
                    position: absolute;
                    top: calc(50% + 0.1em);
                    left: $padding;
                }

            }

            &.inactive {
                button:after {
                    width: calc(100% - #{$padding * 2});
                }
            }
        }
    }
}
