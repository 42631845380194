@import "../../common/common.scss";

.bio {
    font-size: 20px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    line-height: 1.4em;

    // @include neumorphic;
    // padding: 20px;
}
