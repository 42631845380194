$background: #ecedf1;
$active-color: #6ff28a;

$primary: rgb(57, 54, 240);
$secondary: adjust-color($primary, $hue: 120deg);
$trimary: adjust-color($primary, $hue: 240deg);

$anim-time: 0.25s;

$shadow-offset: 5px;
$shadow-blur: 10px;
$radius: 20px;

$shadow-offset-small: $shadow-offset / 2;
$shadow-blur-small: $shadow-blur / 2;

@mixin neumorphic {
    background: $background;
    box-shadow:
        -$shadow-offset (-$shadow-offset) $shadow-blur #fafbff,
        $shadow-offset $shadow-offset $shadow-blur #a6abbd;
    border-radius: $radius;
}

@mixin neumorphic-inset {
    background: $background;
    box-shadow:
        -$shadow-offset (-$shadow-offset) $shadow-blur #fafbff inset,
        $shadow-offset $shadow-offset $shadow-blur #a6abbd inset;
    border-radius: $radius;
}

@mixin neumorphic-small {
    background: $background;
    box-shadow:
        -$shadow-offset-small (-$shadow-offset-small) $shadow-blur-small #fafbff,
        $shadow-offset-small $shadow-offset-small $shadow-blur-small #a6abbd;
}

@mixin neumorphic-small-inset {
    background: $background;
    box-shadow:
        -$shadow-offset-small (-$shadow-offset-small) $shadow-blur-small #fafbff inset,
        $shadow-offset-small $shadow-offset-small $shadow-blur-small #a6abbd inset;
}


$sidebar-spacing: 20px;
$sidebar-width: 250px;

$sidebar-burger-size: 40px;
$sidebar-burger-margin: 5px;
$sidebar-burger-full: $sidebar-burger-size + ($sidebar-burger-margin * 2);


@mixin normalise-button {
    font-family: inherit; /* 1 */
    font-size: 100%; /* 1 */
    line-height: 1.15; /* 1 */
    margin: 0; /* 2 */
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    border-style: none;
    padding: 0;
    background: none;
}
